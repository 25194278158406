<template>
    <div :class="{card: !print}">
        <h3>Registro presenze</h3>
        <b-form-group id="drivingLicenseId" label="Patente" label-for="drivingLicenseId" description="" v-if="!print">
            <b-form-select v-model="drivingLicenseId" @change="filterByDrivingLicense">
                <option value="-1">TUTTE</option>
                <option v-for="license in drivingLicenses" v-bind:value="license.id" :key="license.id">
                    {{ license.name }}
                </option>
            </b-form-select>
        </b-form-group>
        <hr/>

        <div v-for="course in courses"
             :key="course.id">
            <div v-if="filterByCourseId(course.id).length > 0" style="margin-bottom: 25px">
                <h5>Corso {{ course.name || course.id }}</h5>
                <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th scope="col" width="80">Data</th>
                        <th scope="col" class="text-left">Insegnante</th>
                        <th scope="col" class="text-center">Presenza</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="attendance in filterByCourseId(course.id)"
                        :key="attendance.id"
                    >
                        <td>{{ attendance.registeredAt | moment }}</td>
                        <td>{{ attendance.course.teacher.firstname }} {{ attendance.course.teacher.lastname }}</td>
                        <td class="text-center">
                            <span v-if="attendance.isRemote"><b-icon icon="cloud"/> online</span>
                            <span v-else><b-icon icon="house"/> in classe</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <b-row>
                    <b-col class=" font-weight-bold">Tempo totale di presenza (in ore)</b-col>
                    <b-col class="text-right font-weight-bold">{{ attendanceTime(course.id) }}</b-col>
                </b-row>
            </div>
        </div>

    </div>
</template>

<script>
import AttendanceDataService from "@/components/attendance/AttendanceDataService";
import CourseDataService from "@/components/course/CourseDataService";
import moment from "moment";
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";

export default {
    name: "attendance-index",
    data() {
        return {
            allAttendances: [],
            attendances: [],
            courses: [],
            programTypes: [{name: 'Teoria', value: 'lesson'}, {name: 'Guida', value: 'practice'}],
            drivingLicenses: [],
            drivingLicenseId: -1
        };
    },
    props: ['studentId','print'],
    methods: {
        retrieveAttendances() {
            let id = this.studentId ||  this.$store.state.user.profileId;
            AttendanceDataService.getAllByStudent(id)
                .then(response => {
                    this.attendances = this.allAttendances = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        filterByCourseId(courseId) {
            return this.attendances.filter(a => a.courseId == courseId)
        },
        filterByDrivingLicense() {
            if (this.drivingLicenseId > -1) this.attendances = this.allAttendances.filter(item => item.course.program.drivingLicenseId == this.drivingLicenseId);
            else this.attendances = this.allAttendances;
        },
        attendanceTime(courseId) {
            let seconds = 0;
            let attendaces = this.filterByCourseId(courseId);
            for (let attendace of attendaces) {
                const duration = moment(attendace.finishedAt).diff(moment(attendace.registeredAt), 'seconds');
                seconds += duration;
            }

            return moment.utc(seconds*1000).format('HH:mm:ss');
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY - HH:mm');
        }
    },
    mounted() {
        this.message = '';
        this.retrieveAttendances();
        CourseDataService.getAll()
            .then(response => {
                this.courses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    },
}
</script>

<style scoped>

</style>
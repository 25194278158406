<template>
<div>
    <div id="print-button"><a href="javascript:print();" class="btn btn-outline-secondary"><b-icon-printer/> Stampa</a></div>
    <div id="cover" class="text-center" v-if="school && student">
        <h1 class="big">Libretto</h1>
        <h1>Studente {{ student.firstname }} {{ student.lastname }}</h1>
        <h2 class="school-name">{{ school.name }}</h2>
        <p>{{ school.address }}</p>
    </div>

    <div class="container-a4">
        <activity-log-print :student-id="id"/>

        <attendance-index :student-id="id" :print="true" />
    </div>
</div>
</template>

<script>
import ActivityLogPrint from "@/components/activity_log/ActivityLogPrint";
import AttendanceIndex from "@/components/attendance/AttendanceIndex";
import StudentDataService from "@/components/student/StudentDataService";
import SchoolDataService from "@/components/school/SchoolDataService";

export default {
    name: "booklet",
    components: {ActivityLogPrint, AttendanceIndex},
    data() {
        return {
            id: null,
            student: null,
            school: null,
        };
    },
    props: ['studentId'],
    methods: {

    },
    beforeMount() {
        if (this.hasRole('owner') || this.hasRole('admin')) this.id = this.studentId;
        else this.id = this.$store.state.user.profileId;
    },
    mounted() {
        StudentDataService.get(this.id)
            .then(response => {
                this.student = response.data;
                SchoolDataService.getInfo(this.$store.state.schoolId)
                    .then(response => {
                        this.school = response.data;
                        //print();
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch(e => {
                console.log(e);
            });
    },
};
</script>

<style scoped>

body {
    background-color: #fff !important;
    margin: 0 !important;
}

.container-a4 {
    font-size: 1.2em;
    background-color: #fff;
    padding: 30pt 110pt;
}

#cover {
    background: url("../../assets/libretto-bg.jpg") center center no-repeat;
    background-size: contain;
    height: 842pt;
    height: 1160pt;

    padding-top: 250pt;
    color: #fff;
    text-align: center;
}

#cover h1.big {
    font-size: 80pt;
}
#cover .school-name {
    margin-top: 600pt;
}

.container-a4, #cover {
    width: 595pt;
    width: 833pt;
    font-size: 1.2em;
    background-color: #fff;
}

@media only screen {
    .container-a4, #cover {
        margin: 0px auto;
    }
}

#print-button {
    position: fixed;
    top: 25px;
    right: 25px;
}
#print-button a {
    color: #000;
}

</style>

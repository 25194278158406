import http from "../../helpers/http-common";

class StudentDataService {
    getAll(archived) {
        return http.get(`/students/?archived=${archived}`);
    }

    get(id) {
        return http.get(`/students/${id}`);
    }

    create(data) {
        return http.post("/students", data);
    }

    update(id, data) {
        return http.put(`/students/${id}`, data);
    }

    archive(id) {
        return http.get(`/students/archive/${id}`);
    }

    unarchive(id) {
        return http.get(`/students/unarchive/${id}`);
    }

    delete(id) {
        return http.delete(`/students/${id}`);
    }

    deleteAll() {
        return http.delete('/students');
    }

    findByName(name, archived) {
        return http.get(`/students?name=${name}&archived=${archived}`);
    }
}

export default new StudentDataService();

<template>


    <div>
        <h3>Attività svolte</h3>
        <p class="small text-muted">
            Leggenda: <b-icon icon="house" /> in presenza
            &nbsp;
            <b-icon icon="cloud"/> online
        </p>

        <div v-for="program in programs"
             :key="program.id"
             class=""
             style="margin-bottom: 25px"
        >
            <div class="">
                <b-row>
                    <b-col sm="10">
                        <h5>{{ program.name }} ({{ programTypes.find(item => item.value == program.type).name }})</h5>
                    </b-col>
                    <b-col sm="2" class="text-right">
                        <b-icon icon="house" v-if="program.type == 'lesson'" />
                        &nbsp;
                        <b-icon icon="cloud" v-if="program.type == 'lesson'" />
                    </b-col>
                </b-row>
            </div>
            <hr>
            <div class="" v-if="program.type == 'lesson'">

                <topic-tree-view
                    v-for="topic in program.topics"
                    :topic="topic"
                    :key="topic.id"
                    :topics="topic.children"
                    :label="topic.title"
                    :depth="0"
                    :activity-logs="activityLogs"
                    :program="program"
                    :print="true"
                />
            </div>
            <div class="" v-if="program.type == 'practice'">
                <practice-topic-tree-view
                    v-for="topic in program.topics"
                    :topic="topic"
                    :key="topic.id"
                    :topics="topic.children"
                    :label="topic.title"
                    :depth="0"
                    :activityLogs="activityLogs"
                    :showAllTopics="true"
                    :print="true"
                />
                <b-row>
                    <b-col class=" font-weight-bold">Tempo totale di guida (in ore)</b-col>
                    <b-col class="text-right font-weight-bold">{{ practiceTime(program.id) }}</b-col>
                </b-row>

            </div>

        </div>

        <div v-if="programs.length == 0">
            <p><i>Nessuna attività svolta</i></p>
        </div>

    </div>

</template>

<script>
import ActivityLogDataService from "@/components/activity_log/ActivityLogDataService";
import TopicTreeView from "@/components/activity_log/TopicTreeView";
import PracticeTopicTreeView from "@/components/practice/PracticeTopicTreeView";
import TopicDataService from "@/components/topic/TopicDataService";
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";
import moment from "moment";

export default {
    name: "activity-log-print",
    components: {TopicTreeView, PracticeTopicTreeView},
    data() {
        return {
            programs: [],
            programTypes: [{name: 'Teoria', value: 'lesson'}, {name: 'Guida', value: 'practice'}],
            activityLogs: [],
            currentProgramId: -1,
            drivingLicenses: [],
            drivingLicenseId: -1
        };
    },
    props: ['studentId','programId'],
    methods: {
        retrieveActivityLogs() {
            this.programs = [];
            let id = this.studentId ||  this.$store.state.user.profileId;

            if (this.programId) {
                ActivityLogDataService.getByProgramAndStudent(id, this.programId)
                    .then(response => {
                        this.activityLogs = response.data;
                        let currentProgramId = -1;
                        for (let activityLog of this.activityLogs) {
                            // recupero tutti i programmi presenti nelle attività svolte
                            if (currentProgramId != activityLog.programId) {
                                currentProgramId = activityLog.programId
                                this.retrieveTopics(activityLog);
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else if (this.drivingLicenseId > -1) {
                ActivityLogDataService.getAllByStudentAndDrivingLicense(id, this.drivingLicenseId)
                    .then(response => {
                        this.activityLogs = response.data;
                        let currentProgramId = -1;
                        for (let activityLog of this.activityLogs) {
                            // recupero tutti i programmi presenti nelle attività svolte
                            if (currentProgramId != activityLog.programId) {
                                currentProgramId = activityLog.programId
                                this.retrieveTopics(activityLog);
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                ActivityLogDataService.getAllByStudent(id)
                    .then(response => {
                        this.activityLogs = response.data;
                        let currentProgramId = -1;
                        for (let activityLog of this.activityLogs) {
                            // recupero tutti i programmi presenti nelle attività svolte
                            if (currentProgramId != activityLog.programId) {
                                currentProgramId = activityLog.programId
                                this.retrieveTopics(activityLog);
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },
        retrieveTopics(activityLog) {
            TopicDataService.getAll(activityLog.programId)
                .then(response => {
                    function countMandatoriesRecursive(topics) {
                        let i = 0;
                        for (const topic of topics) {
                            if (topic.mandatory === true) i++;
                            if (topic.children) i += countMandatoriesRecursive(topic.children);
                        }
                        return i;
                    }
                    const countMandatories = countMandatoriesRecursive(response.data.rows);
                    let program = {
                        id: activityLog.programId,
                        name: activityLog.program.name,
                        type: activityLog.program.type,
                        topics: response.data.rows,
                        count: response.data.count,
                        countMandatory: countMandatories
                    }
                    this.programs.push(program);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        countDistinctActivityCompleted(programId) {
            const completed = this.activityLogs.filter(x => x.programId === programId && x.status === 'Completed' && x.topic.mandatory);
            const uniqueCompleted = [...new Set(completed.map(item => item.topicId))]; // [ 'A', 'B']
            return uniqueCompleted.length;
        },

        practiceTime(programId) {
            let seconds = 0;
            for (let activity of this.activityLogs) {
                if (activity.programId == programId) {
                    const duration = moment(activity.practice.dateEnd).diff(moment(activity.practice.dateStart), 'seconds');
                    seconds += duration;
                }
            }
            return moment.utc(seconds*1000).format('HH:mm:ss');
        },

        watchHandler() {
            this.programs = [];
            this.activityLogs = [];
            this.retrieveActivityLogs();
        }

    },
    mounted() {
        this.retrieveActivityLogs();
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        this.$watch('programId', this.watchHandler);
    },
    /*
    watch: {
        $props: {
            handler() {
                this.programs = [];
                this.activityLogs = [];
                this.retrieveActivityLogs();
            },
            deep: true,
            immediate: true,
        }
    }

     */
};
</script>

<style scoped>

body {
    background-color: #fff !important;
    margin: 0 !important;
}

.container-a4 {
    font-size: 1.2em;
    background-color: #fff;
}

#cover {
    background: url("../../assets/libretto-bg.jpg") center center no-repeat;
    background-size: contain;
    height: 842pt;
}

.container-a4, #cover {
    font-size: 1.2em;
    width: 595pt;
    background-color: #fff;
}

@media only screen {
    .container-a4 {
        padding: 25px;
    }
    .container-a4, #cover {
        margin: 0px auto;
    }
}

</style>

import http from "../../helpers/http-common";

class CourseDataService {
    getAll() {
        return http.get("/courses");
    }

    get(id) {
        return http.get(`/courses/${id}`);
    }

    create(data) {
        return http.post("/courses", data);
    }

    update(id, data) {
        return http.put(`/courses/${id}`, data);
    }

    open(id, data) {
        return http.put(`/courses/open/${id}`, data);
    }

    close(id, data) {
        return http.put(`/courses/close/${id}`, data);
    }

    delete(id) {
        return http.delete(`/courses/${id}`);
    }

    deleteAll() {
        return http.delete('/courses');
    }

    findByName(name) {
        return http.get(`/courses?name=${name}`);
    }
}

export default new CourseDataService();
